import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

// import heroStyles from '../components/hero.module.css'

const Div = styled.div`
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  img {
    width: auto;
    max-width: 100%;
  }

  .heroImage {
    position: relative;
    height: 600px;
    width: 600px;
    max-height: 450px;
  }

  @media (max-width: 600px) {
    .heroImage {
      width: 100%;
    }
  }
`

class NewsPostTemplate extends React.Component {
  render() {
    const post = this?.props?.data?.contentfulNews

    return (
      <Layout location={this.props.location}>
        <Div
          style={
            {
              // background: '#f2f1e2',
              // borderRadius: 5,
              // boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
            }
          }
        >
          <Helmet title={post.title} />
          <div className="wrapper">
            <h1 className="section-headline">{post.title}</h1>
            <p
              style={{
                display: 'block',
              }}
            >
              {post.publishDate}
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </div>
        </Div>
      </Layout>
    )
  }
}

export default NewsPostTemplate

export const pageQuery = graphql`
  query NewsPostQuery($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
